import {BiHappyHeartEyes} from 'react-icons/bi'
import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'


const data = [
    {id:1, icon:<FaAward/>, title: 'Experience', desc: '8+ Months Working'},
    {id:2, icon:<TbBooks/>, title: 'Projects', desc: '4+ Completed'},
    {id:3, icon:<BiHappyHeartEyes/>, title: 'Skills', desc: '4+ Skills Acquired'},

]
 export default data;