import Card from "../../components/Card.jsx"
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
import { useState } from "react"


const FAQ = ({faq}) => {
    const [showAnswer, setShowAnswer] = useState(false);



  return (
    <Card className="faq light" onClick={() => setShowAnswer(prev => !prev)}>
         <div>
        <div className="faqs__questions-container" data-aos= "fade-in">
          <div>
            <h5 className="faqs__question">{faq.question}</h5>
          </div>
          <div>
            <h7 className="faqs__question2">{faq.question2}</h7>
          </div>
          <div>
            <h7 className="faqs__question3">{faq.question3}</h7>
          </div>
        </div>
            <button className="faq__icon">
                {showAnswer ? <AiOutlineMinus/> : <AiOutlinePlus/>}
            </button>
        </div>
        {showAnswer &&
      <div className="faq__answers-container" data-aos = "fade-in">
      <div className="faq__answer">{faq.answer}</div>
      {faq.answer2 && <div className="faq__answer2">{faq.answer2}</div>}
      {faq.answer3 && <div className="faq__answer3">{faq.answer3}</div>}

    </div>
      }
    </Card>
  )
}

export default FAQ