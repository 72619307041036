import AboutImage from '../../assets/header.jpg'
import CV from '../../assets/Resume-2.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about">
        <div className="container about__container" data-aos="fade-in">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="AboutImage" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Me</h2>
                <div className="about__cards"data-aos="fade-up">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p data-aos="fade-up">
                Hi my name is Vishvam Patel, and as I embark on my journey to secure an internship for Summer 2024, I'm excited about sharing my portfolio with you. With a passion for all things tech and insatible hunger to learn, I'm a 2A computer engineering student at the Univerity of Waterloo.
                </p>
                <p data-aos="fade-up">
                My website highlights some of my key projects and work experience. So why not stick around, learn a bit more what I'm all about, and see how I can bring value to your team! Check out my resume below!
                </p>
                <a data-aos="fade-up" href={CV} download className='btn primary'>Download CV <HiDownload/> </a>
            </div>
        </div>
    </section>
  )
}

export default About