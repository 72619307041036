import {IoLogoPython} from 'react-icons/io'
import {SiCplusplus} from 'react-icons/si'
import {CgWebsite} from 'react-icons/cg'
import {GiSkills} from 'react-icons/gi'

const data = [
    {id:1, icon: <IoLogoPython/>, title: 'Python', desc: "My experience as an AWS cloud computing professional and executing multiple successful projects, including the development of scalable applications has anabled me to develop a strong command in Python."},
    {id:2, icon: <SiCplusplus/>, title: 'C++', desc: "I gained proficiency in C++ through coursework and projects. During my first term, I gained valuable hands-on experience through which I have developed a depeper understanding of C++."},
    {id:3, icon: <CgWebsite/>, title: 'React, Java, CSS, HTML', desc:"Through building my own portfolio website and completing courses on Udemy, I have acquired a diverse range of skills in web-development. With this experience I am able to design and develop dynamic web applications with mordern and responsive UI's that provide seamless user experience."},
    {id:4, icon: <GiSkills/>, title: 'SoftSkills', desc: "Time and task management, excellent communication skills, Teamwork and Leadership."}

]

export default data