import faqs from './data'
import FAQ from './FAQ'
import './experience.css'

const FAQs = () => {
  return (
    <section id="faqs">
      <h2>Work/Volunteer Experience</h2>
      <p>
      Here are some places I have worked and volunteered at. Click to toggle the description, and if you have more queries, shoot me a message from the contact section!
      </p>
      <div className="container faqs__container">
        {
          faqs.map(faq => (
            <FAQ key={faq.id} faq={faq}/>
          ))
        }
      </div>
    </section>
  )
}

export default FAQs