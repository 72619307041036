const data = [
      {
      id: 1,
      question: "Technical Product Manager Intern ",
      question2: "Sungrid Solutions",
      question3: "September - December 2023 (4 months) ",
      answer: "- Led backend development for a Python organizational chart, integrating Smartsheet data with interactive D3.js features.",
      answer2: "- Led team to promptly resolve SQL, Python, and cloud infrastructure software issues, boosting efficiency and resilience",
      answer3: "- Guided Smartsheet optimization, crafting workflows, dashboards, and automations, achieving a 90% backlog reduction."
    },
    {
      id: 2,
      question: "Software Engineering Intern",
      question2: "Avolta Inc.",
      question3: "September - December 2024 ",
      answer: "- Developed a Flask API for user registration, integrating data validation, handling POST/GET requests for mobile app.",
      answer2: "- Utilized SQL tools to design an efficient database for vehicle data including brand-specific tables for data retrieval.",
      answer3: "- Created a function using Python’s CSV module to automate the import of vehicle data from CSVs to their SQL tables.",
      answer4: "- Used pytest to test functions, including updating user info, user registration, and password updates"
    },
    {
      id: 3,
      question: "Cloud Developer",
      question2: "ISED Canada (Co-op)",
      question3: "January - April 2023 (4 months) ",
      answer: "- Created CloudWatch Alarms using CDK and a Lambda function in order to be alerted when a virtual machine fails.",
      answer2: "- Built a EC2 Instance Scheduler from scratch using cloudformation services, and AWS CDK and SDK.",
      answer3: "- Gained profiency in AWS cloud-services through completing various projects and mini-tasks."
    },
    {
      id: 4,
      question: "Pharmacy Assistant",
      question2: "Loblaws Pharmacy (Part-Time)",
      question3: "Feb - August 2021 ",
      answer: "- Reduced data entry errors by identifying problem points and implementing additional data verification steps.",
      answer2: "- Worked collectively as a team to provide superior customer service on the sales floor and on the cash register.",
      answer3: "- Used the pharmacy's computer program to access prescription information, package, and dispense medications. "
    },
    {
      id: 5,
      question: "Tutor",
      question2: "Links to Learning (Volunteer)",
      question3: "Dec 2020 - June 2022 ",
      answer: "- Volunteered over 100 hours tutoring kids with learning disabilities and helped them learn how to pronounce words and understand basic principles of mathematics.",
      answer2: "- Monitored student progress and communicated with parents to ensure that their child's education goals were being met.",
      // answer3: "But in general, you "

    },
    {
      id: 6,
      question: "Cadet Sergeant",
      question2: "608 Dukes: Royal Canadian Air Cadet (Volunteer)",
      question3: "Nov 2018 - March 2020 ",
      answer: "- Gained valuable leadership skills through supervising younger cadets, teaching flight lessons, and tracking their progress.",
      answer2: "- Organized fundraiser events with senior cadets to raise money for the air cadet program.",
      // answer3: "But in general, you "
    },
    {
      id: 7,
      question: "Student Link Crew",
      question2: "Centennial Secondary School (Volunteer)",
      question3: "Dec 2020 - Jun 2022 ",
      answer: "- Implemented initiatives such as mentorship programs for international students, helping them settle into the high school culture in Canada.",
      answer2: "- Worked with other members of the club to organize cultural events at the school.",
    },
    {
      id: 8,
      question: "Library Assistant",
      question2: "Belleville Public Library (Volunteer)",
      question3: "Nov 2018 - March 2020",
      answer: "- Volunteered for Program Management; my duties included co-organization and preparations of material supplies, props, and stalls.",
      // answer2: "- But in general, you need a fair idea of what you want, then we can take it from there.",
      answer2: "- Helped people with issues related to library computers. "
    }
  ]

  export default data
  
  