import {AiFillLinkedin} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'
import {AiFillFacebook} from 'react-icons/ai'

const data = [
    {id: 1, link: 'https://www.linkedin.com/in/vishvam-patel-338539203', icon: <AiFillLinkedin/>},
    {id: 2, link: 'https://www.instagram.com/vishvam_3101/', icon: <AiFillInstagram/>},
    {id: 3, link: 'https://github.com/VishvamPatel31', icon: <AiFillGithub/>},
    {id: 4, link: 'https://www.facebook.com/profile.php?id=100015076277241', icon: <AiFillFacebook/>}
]

export default data

