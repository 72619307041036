import Avatar1 from '../../assets/avatar1.jpg'
import Avatar2 from '../../assets/avatar2.png'
import PSAward from '../../assets/pspencer.jpg'
import Jhenry from '../../assets/jhenry.jpg'

const data = [
    {
        id: 1,
        quote: "Practical Next.js & React ",
        avatar: Avatar1,
        name: 'Udemy',
        profession: 'Completion: 29/03/2023',
        link: 'https://www.udemy.com/certificate/UC-8e72691b-ade0-431b-b952-1c364f9dfec3/'

    },
    {
        id: 2,
        quote: "HTML and CSS (2 in 1) for beginners",
        avatar: Avatar1,
        name: 'Udemy',
        profession: 'Completion: 29/03/2023',
        link: 'https://www.udemy.com/certificate/UC-f4e4c691-d921-40cd-aa4e-5f17f113d498/'
    },
    {
        id: 3,
        quote: "J Henry Engineering Award ",
        avatar: Avatar2,
        name: 'HPEDSB',
        profession: 'Received: 27/06/2022',
        image: Jhenry

    },
    {
        id: 4,
        quote: "Percy Spencer Memorial Award ",
        avatar: Avatar2,
        name: 'HPEDSB',
        profession: 'Received: 27/06/2022',
        image: PSAward

    },
    {
        id: 5,
        quote: "Python3: Beginner to Advanced ",
        avatar: Avatar1,
        name: 'Udemy',
        profession: 'Completion: 23/08/2021',
        link: 'https://www.udemy.com/certificate/UC-8e72691b-ade0-431b-b952-1c364f9dfec3/'
    },
    {
        id: 6,
        quote: "Practical Web Design: 7 Courses in 1",
        avatar: Avatar1,
        name: 'Udemy',
        profession: 'Completion: 22/12/2021',
        link: 'https://www.udemy.com/certificate/UC-05302f8d-cd1e-42a6-b87d-c8154d025c0c/'
    },

]

export default data