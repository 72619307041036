import {HiOutlineMail} from 'react-icons/hi'
import {RiMessengerLine} from 'react-icons/ri'
import {FaWhatsapp} from 'react-icons/fa'
// import { FaInstagram } from 'react-icons/fa'
// import {FaLinkedin} from 'react-icons/fa'

const data = [
    {id: 1, icon: <HiOutlineMail/>, link: 'mailto:vj2patel@uwaterloo.ca'},
    {id: 2, icon: <RiMessengerLine/>, link: 'https://www.messenger.com/t/100015076277241'},
    {id: 3, icon: <FaWhatsapp/>, link: 'https://wa.me/+16139703800'},
  
    
]

export default data