
import Image1 from '../../assets/project1.jpeg'
import Image2 from '../../assets/project2.jpeg'
import Image3 from '../../assets/project3.jpeg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/pro5.png'
import Image6 from '../../assets/project6.jpeg'
import Image7 from '../../assets/A1.png'

const data = [

    {
        id: 1,
        category: 'Python',
        image: Image5,
        title: "MEDICAI",
        desc: "Developed machine learning app using AWS Sagemaker and MERN stack that recognizes Pneumonia based on X-rays. \
Established and configured Hyperparameter Tuning Jobs in AWS, employing GPU instances for accelerated training. \
Conducted in-depth Exploratory Data Analysis using Matplotlib, Seaborn, Pandas, and Numpy within AWS Sagemaker. \
Deployed deep learning models on the Amazon Cloud infrastructure by integrating API Gateway and Lambda functions. \
Configured AutoScaling for deployed endpoints in AWS to prevent latency issues and maintain optimal performance. \
Orchestrated the end-to-end development and deployment of a MongoDB, Express, Node.js, React/Next.js application.",
        demo: 'https://replit.com/@Vishvam31/Sentiment-Analysis?v=1',
        github: 'https://github.com/VishvamPatel31/MEDIC_AI'
    },
    {
        id: 2,
        category: 'Frontend',
        image: Image7,
        title: "Jokeoverflow.com",
        desc: "Developed a web app utilizing API integrations for real-time dynamic joke translation using Google Cloud Translation. Employed JavaScript to fetch and translate jokes based on chosen user languages, enriching multilingual user experience. \
    Utilized asynchronous requests to integrate JokeAPI, dynamically displaying real-time diverse jokes on the web app.",
        demo: 'https://replit.com/@Vishvam31/Sentiment-Analysis?v=1',
        github: 'https://64c2787ac3372106f797df0f--ephemeral-crumble-d9f156.netlify.app'
    },
    {
        id: 2,
        category: 'Python',
        image: Image1,
        title: "Bot Converse (Python)",
        desc: "Create a sophisticated chatbot that leverages cutting-edge technologies to provide an intelligent and interactive conversation experience. The project utilizes Python for backend development, FastAPI for building a robust API, OpenAI for natural language processing, and Deli for dynamic image generation. ",
        demo: 'https://replit.com/@Vishvam31/Sentiment-Analysis?v=1',
        github: 'https://github.com/VishvamPatel31/bot_converse'
    },
    {
        id: 3,
        category: 'Python',
        image: Image2,
        title: "AudioArithmetic (Python)",
        desc: "Used Python's tkinter module to create a GUI based Smart Calculator application that performs complex arithmetic operations and trigonometric functions. Implemented speech recognition functionality that allows users to perform calculations using voice commands.",
        demo: 'https://replit.com/@Vishvam31/Tic-Tac-Toe-Game?v=1',
        github: 'https://github.com/VishvamPatel31/AudioArithmetic'
    },   
    {
        id: 4,
        category: 'Python',
        image: Image3,
        title: "FaceFinderX (Python)",
        desc: "Used OpenCV to detect faces and eyes in a video stream, draw rectangles around them, and display annotated frames. Employed the Haar cascade classifiers for face and eye detection, and the NumPy library for numerical computations.",
        demo: 'https://academy.cs.cmu.edu/exercise/2343/',
        github: 'https://github.com/VishvamPatel31/Face-detection'
    },
    {
        id: 5,
        category: 'Frontend',
        image: Image4,
        title: "Portfolio Website (Frontend)",
        desc: "Created my own portfolio website using React, CSS, Java, and HTML. Website includes features like theme-customization and provides seamless user experience.",
        demo: 'https://replit.com/@Vishvam31/Sentiment-Analysis?v=1',
        github: 'https://github.com/VishvamPatel31/Portfolio-Web'
    },
    {
        id: 6,
        category: 'Python',
        image: Image6,
        title: "Tictactoe (Python)",
        desc: "Used Python to create a command-line version of Tic Tac Toe game, which introduces the rules of the game, creates a blank game board, allows the players to choose their symbols and then starts the game, taking turns in marking spaces on the 3x3 grid. The game checks if a player has won or if the board is full, ending the game and giving a report of the game's outcome.",
        demo: 'https://academy.cs.cmu.edu/exercise/2343/',
        github: 'https://github.com/VishvamPatel31/Tictactoe-game'
    },
]
    

export default data