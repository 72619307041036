import Card from "../../components/Card"
import { BiLinkExternal } from "react-icons/bi";

const Testimonial = ({testimonial}) => {
  return (
    <Card className="white" >
        <h5 data-aos="fade-in">{testimonial.quote}</h5>
        <div className="testimonial__client" data-aos="fade-in">
            <div className="testimonial__client-avatar" data-aos="fade-in">
                <img src={testimonial.avatar} alt="Testimonial Avatar" />
            </div>
            <div className="testimonial__client-details">
                <h6>{testimonial.name}</h6>
                <small>{testimonial.profession}</small>
            </div>
            <div className="testimonial__client-link" data-aos="fade-in">
            {/* <h6><a href={testimonial.link} className="btn sm" target="_blank" rel="noopener noreferrer">
                <BiLinkExternal /> </a></h6> */}
                {testimonial.link ?
                <h6 data-aos="fade-in"><a href={testimonial.link} className="btn sm" target="_blank" rel="noopener noreferrer">
                  <BiLinkExternal /> </a></h6> :
                <h6 data-aos="fade-in"><a href={testimonial.image} className="btn sm" target="_blank" rel="noopener noreferrer">
                <BiLinkExternal /></a></h6>
              }
            </div>
        </div>
    </Card>
  )
}

export default Testimonial